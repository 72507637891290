import * as d3 from "d3";

import * as Style from "style";
import { EmployeeTimelineEvent } from "../type";
import { TenureCategory } from "../RangeAdheranceChart/types";
import { Gender } from "@milio/lib/data/employee/constant";
import { PaybandAdherance } from "@milio/lib/data/plan/type";
import { PerformanceCategory } from ".generated/models";

export enum CompensationCategory {
  TotalCompensation = "total",
  EmployeeCount = "count",
}
export const CompensationCategoryLabels = {
  [CompensationCategory.TotalCompensation]: "total comp",
  [CompensationCategory.EmployeeCount]: "employee count",
};
export const compColors = d3
  .scaleOrdinal()
  .domain([
    CompensationCategory.TotalCompensation,
    CompensationCategory.EmployeeCount,
  ])
  .range([Style.Color.Chart.CornflowerBlue, Style.Color.Chart.Saffron]);

export enum GenderCategory {
  Group = "group",
}

export const GenderCategoryLabels = {
  [Gender.Male]: "Male",
  [Gender.Female]: "Female",
  [Gender.Other]: "Other",
  [Gender.NotSpecified]: "Not Specified",
  [GenderCategory.Group]: "Group",
};
export const genderColors = d3
  .scaleOrdinal<string, string>()
  .domain([
    Gender.Male,
    Gender.Female,
    Gender.Other,
    Gender.NotSpecified,
    GenderCategory.Group,
  ])
  .range(Style.ChartColors);

export const historicalSpendColors = d3
  .scaleOrdinal<string, string>()
  .range(Style.ChartColors);

export const PerformanceCategoryLabels: {
  [key in PerformanceCategory]: string;
} = {
  [PerformanceCategory.NoData]: "No Performance Data",
  [PerformanceCategory.DoesNotMeet]: "Does Not Meet Expectations",
  [PerformanceCategory.Meets]: "Meets Expectations",
  [PerformanceCategory.Exceeds]: "Exceeds Expectations",
  [PerformanceCategory.FarExceeds]: "Far Exceeds Expectations",
};

export const performanceColors = d3
  .scaleOrdinal<string, string>()
  .domain([
    PerformanceCategory.NoData,
    PerformanceCategory.DoesNotMeet,
    PerformanceCategory.Meets,
    PerformanceCategory.Exceeds,
    PerformanceCategory.FarExceeds,
  ])
  .range([
    Style.Color.Layout.Gray,
    Style.Color.Chart.Mandy,
    Style.Color.Chart.Saffron,
    Style.Color.Chart.Fern,
    Style.Color.Chart.CornflowerBlue,
  ]);

export enum AgeCategory {
  Under18 = "under 18",
  Range18To29 = "18-29",
  Range30To39 = "30-39",
  Range40To49 = "40-49",
  Range50To59 = "50-59",
  Range60To69 = "60-69",
  Range70Plus = "70+",
  Group = "group",
}
export const AgeCategoryLabels = {
  [AgeCategory.Under18]: "Under 18",
  [AgeCategory.Range18To29]: "18-29",
  [AgeCategory.Range30To39]: "30-39",
  [AgeCategory.Range40To49]: "40-49",
  [AgeCategory.Range50To59]: "50-59",
  [AgeCategory.Range60To69]: "60-69",
  [AgeCategory.Range70Plus]: "70+",
  [AgeCategory.Group]: "group",
};
export const ageColors = d3
  .scaleOrdinal<string, string>()
  .domain([
    AgeCategory.Under18,
    AgeCategory.Range18To29,
    AgeCategory.Range30To39,
    AgeCategory.Range40To49,
    AgeCategory.Range50To59,
    AgeCategory.Range60To69,
    AgeCategory.Range70Plus,
    AgeCategory.Group,
  ])
  .range(Style.ChartColors);

export const paybandColors = d3
  .scaleOrdinal<string, string>()
  .domain([
    PaybandAdherance.Above,
    PaybandAdherance.Below,
    PaybandAdherance.InBand,
    PaybandAdherance.NoPayband,
  ])
  .range([
    Style.Color.Chart.CornflowerBlue,
    Style.Color.Chart.Fern,
    Style.Color.Chart.Saffron,
    Style.Color.Chart.Mandy,
  ]);

export const tenureColors = (name: string): d3.ScaleOrdinal<string, string> =>
  d3
    .scaleOrdinal<string, string>()
    .domain([
      TenureCategory.Less,
      TenureCategory.More,
      TenureCategory.Same,
      TenureCategory.Multiple,
      name,
    ])
    .range([
      Style.Color.Chart.Mandy,
      Style.Color.Chart.Fern,
      Style.Color.Layout.Gray,
      Style.Color.Chart.Saffron,
      Style.Color.Chart.CornflowerBlue,
    ]);

export enum PlanChangeCategory {
  Other = "other",
  Current = "current",
  Proposed = "proposed",
}
export const PlanChangeCategoryLabels = {
  [PlanChangeCategory.Current]: "current",
  [PlanChangeCategory.Other]: "other",
  [PlanChangeCategory.Proposed]: "proposed",
};

export const MeritCycleCategoryLabels = {
  [PlanChangeCategory.Current]: "this team",
  [PlanChangeCategory.Other]: "other team",
  [PlanChangeCategory.Proposed]: "proposed",
};

export const planChangeColors = d3
  .scaleOrdinal<string, string>()
  .domain([
    PlanChangeCategory.Other,
    PlanChangeCategory.Current,
    PlanChangeCategory.Proposed,
  ])
  .range([
    Style.Color.Layout.Gray,
    Style.Color.Chart.CornflowerBlue,
    Style.Color.Chart.Fern,
  ]);

export const tenureVsAll = (
  name: string
): d3.ScaleOrdinal<string, unknown, never> =>
  d3.scaleOrdinal().domain([name]).range([Style.Color.Chart.CornflowerBlue]);
export const geoColors = (
  geos: string[]
): d3.ScaleOrdinal<string, string, never> =>
  d3.scaleOrdinal<string, string>().domain(geos).range(Style.ChartColors);

export const PeopleFlowColors = d3
  .scaleOrdinal<string, string>()
  .range([
    Style.Color.Chart.CornflowerBlue,
    Style.Color.Chart.Saffron,
    Style.Color.Chart.Studio,
    Style.Color.Chart.EnergyYellow,
    Style.Color.Chart.PuertoRico,
    Style.Color.Chart.PersianPink,
    Style.Color.Chart.CeruleanBlue,
    Style.Color.Chart.BrightSun,
    Style.Color.Chart.MulledWine,
    Style.Color.Chart.Chino,
    Style.Color.Chart.Lavender,
    Style.Color.Chart.DeYork,
    Style.Color.Chart.TonysPink,
  ]);

export const EmployeeTimelineLabels = {
  [EmployeeTimelineEvent.BecameInactive]: "Inactive",
  [EmployeeTimelineEvent.DepartmentChange]: "Department",
  [EmployeeTimelineEvent.EquityGrant]: "Equity",
  [EmployeeTimelineEvent.ExemptionChange]: "Exemption",
  [EmployeeTimelineEvent.Hire]: "Hired",
  [EmployeeTimelineEvent.Multiple]: "Multiple",
  [EmployeeTimelineEvent.ManagerChange]: "Manager",
  [EmployeeTimelineEvent.PayChange]: "Comp Change",
  [EmployeeTimelineEvent.PerformanceReview]: "Performance",
  [EmployeeTimelineEvent.ReturnedFromLeave]: "Returned from Leave",
  [EmployeeTimelineEvent.TitleChange]: "Title",
  [EmployeeTimelineEvent.WentOnLeave]: "Went on Leave",
};

export const employeeTimelineColors = d3
  .scaleOrdinal<string, string>()
  .domain([
    EmployeeTimelineEvent.PayChange,
    EmployeeTimelineEvent.Hire,
    EmployeeTimelineEvent.Multiple,
    EmployeeTimelineEvent.TitleChange,
    EmployeeTimelineEvent.DepartmentChange,
    EmployeeTimelineEvent.ManagerChange,
    EmployeeTimelineEvent.ExemptionChange,
    EmployeeTimelineEvent.EquityGrant,
    EmployeeTimelineEvent.BecameInactive,
    EmployeeTimelineEvent.PerformanceReview,
    EmployeeTimelineEvent.WentOnLeave,
    EmployeeTimelineEvent.ReturnedFromLeave,
  ])
  .range([
    Style.Color.Chart.Fern,
    Style.Color.Chart.BrightSun,
    Style.Color.Chart.Lavender,
    Style.Color.Chart.CornflowerBlue,
    Style.Color.Chart.CeruleanBlue,
    Style.Color.Chart.Chino,
    Style.Color.Chart.MulledWine,
    Style.Color.Chart.PersianPink,
    Style.Color.Chart.TonysPink,
    Style.Color.Chart.Studio,
    Style.Color.Chart.Mandy,
    Style.Color.Chart.Saffron,
  ]);
