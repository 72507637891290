import { MetricType, RuleSchema } from "./type";

import { DataType } from "../report/constant";
import { ResourceType } from "../constant";
import { RuleAggregationType, CollectionPeriod, RuleType } from "./type";
import { AlertSchema } from "./type";

export enum RuleID {
  CompaRatioOutliers = "compa-ratio-drift",
  DepartmentChange = "department-change",
  ExemptionStatusChange = "exemption-status-change",
  FlightRisk = "flight-risk",
  GenderPayComparison = "gender-pay-comparison",
  HourlyPayChange = "hourly-pay-change",
  JobTitleChange = "job-title-change",
  LastCompIncreaseOutliers = "last-comp-increase-drift",
  LastPromotionOutliers = "tenure-comparison",
  ManagerChange = "manager-change",
  MissingPaybands = "missing-paybands",
  NewHires = "new-hire",
  OutsideOfPayBand = "outside-of-pay-band",
  PayChange = "pay-change",
  PayCompression = "pay-compression",
  StalePlans = "stale-paybands",
  StalePayChanges = "stale-pay-changes",
  StaleTitleChanges = "stale-title-changes",
  StatusChange = "status-change",
  UnmappedJobTitles = "unmapped-job-titles",
}

export const CollectionPeriodOptions = [
  { value: CollectionPeriod.Immediate, label: "Immediate" },
  { value: CollectionPeriod.Daily, label: "Daily" },
  { value: CollectionPeriod.Weekly, label: "Weekly" },
  { value: CollectionPeriod.Monthly, label: "Monthly" },
];

const CollectionPeriodSchema: AlertSchema = {
  aggregations: [RuleAggregationType.MostRecent],
  key: "collectionPeriod",
  example: "24",
  defaultValue: CollectionPeriod.Immediate,
  title: "Period",
  description: "Time period over which insights are aggregated.",
  enumeration: CollectionPeriodOptions,
  type: DataType.String,
};

const ThresholdSchema: AlertSchema = {
  key: "threshold",
  defaultValue: 0.25,
  example: "24",
  type: DataType.Percent,
};

const BooleanSchema: AlertSchema = {
  defaultValue: true,
  type: DataType.Boolean,
};

export const RuleIDSchema: { [id in RuleID]: RuleSchema } = {
  [RuleID.CompaRatioOutliers]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description:
      "Check if an employee's compa-ratio is above or below the average of their peers.",
    id: RuleID.CompaRatioOutliers,
    label: "Compa-Ratio Outliers",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      threshold: {
        ...ThresholdSchema,
        title: "Threshold",
        description: `If an employee's Compa-Ratio is more than <threshold>% above or below the AVERAGE Compa-Ratio, create an insight.`,
      },
    },
  },
  [RuleID.DepartmentChange]: {
    type: RuleType.EventBased,
    description: `Record when an employee's department changes.`,
    id: RuleID.DepartmentChange,
    label: "Department Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: {
        ...CollectionPeriodSchema,
        defaultValue: CollectionPeriod.Daily,
        enumeration: CollectionPeriodOptions.slice(1),
        aggregations: [RuleAggregationType.Sum],
      },
    },
  },
  [RuleID.ExemptionStatusChange]: {
    type: RuleType.EventBased,
    description:
      "Record when an employee changes from exempt to non-exempt, or vice-versa.",
    id: RuleID.ExemptionStatusChange,
    label: "Exemption Status Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: {
        ...CollectionPeriodSchema,
        defaultValue: CollectionPeriod.Daily,
        enumeration: CollectionPeriodOptions.slice(1),
        aggregations: [RuleAggregationType.Sum],
      },
    },
  },
  [RuleID.FlightRisk]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description: "Check if an employee is considered a flight risk.",
    id: RuleID.FlightRisk,
    label: "Flight Risk",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
    },
  },
  [RuleID.GenderPayComparison]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description:
      "Check how an employee's compensation differs between gender identification among their peers ",
    id: RuleID.GenderPayComparison,
    label: "Gender Pay Comparison",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      threshold: {
        ...ThresholdSchema,
        title: "Threshold",
        description: `If an employee's pay is more than <threshold>% above or below the AVERAGE pay for any gender, create an insight.`,
      },
    },
  },
  [RuleID.HourlyPayChange]: {
    type: RuleType.EventBased,
    description: `Record when an employee's hourly pay rate changes.`,
    id: RuleID.HourlyPayChange,
    label: "Hourly Pay Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: {
        ...CollectionPeriodSchema,
        defaultValue: CollectionPeriod.Daily,
        enumeration: CollectionPeriodOptions.slice(1),
        aggregations: [RuleAggregationType.Sum],
      },
    },
  },
  [RuleID.JobTitleChange]: {
    type: RuleType.EventBased,
    description: `Record when an employee's job title changes.`,
    id: RuleID.JobTitleChange,
    label: "Job Title Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: {
        ...CollectionPeriodSchema,
        defaultValue: CollectionPeriod.Daily,
        enumeration: CollectionPeriodOptions.slice(1),
        aggregations: [RuleAggregationType.Sum],
      },
    },
  },
  [RuleID.LastCompIncreaseOutliers]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description:
      "Check if an employee's time to last comp increase is significantly greater than than their peers.",
    id: RuleID.LastCompIncreaseOutliers,
    label: "Last Compensation Increase Outliers",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      threshold: {
        ...ThresholdSchema,
        title: "Threshold",
        description: `If the time since an employee's last comp increase is more than <threshold>% above the AVERAGE time among peers, create an insight.`,
      },
    },
  },
  [RuleID.LastPromotionOutliers]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description:
      "Check if an employee has been in their current role for more time than their peers.",
    id: RuleID.LastPromotionOutliers,
    label: "Last Promotion Outliers",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      threshold: {
        ...ThresholdSchema,
        title: "Threshold",
        description: `If an employee's tenure is more than <threshold>% above or below the AVERAGE tenure among their peers, create an insight.`,
      },
    },
  },
  [RuleID.ManagerChange]: {
    type: RuleType.EventBased,
    description: `Record when an employee's manager changes.`,
    id: RuleID.ManagerChange,
    label: "Manager Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: {
        ...CollectionPeriodSchema,
        defaultValue: CollectionPeriod.Daily,
        enumeration: CollectionPeriodOptions.slice(1),
        aggregations: [RuleAggregationType.Sum],
      },
    },
  },
  [RuleID.MissingPaybands]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description:
      "Count of the # of Job Title/Zone combinations which do not have a payband attached.",
    id: RuleID.MissingPaybands,
    label: "Missing Paybands",
    resources: [ResourceType.Plan],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
    },
  },
  [RuleID.NewHires]: {
    type: RuleType.EventBased,
    description: "Record when an employee joins the company.",
    id: RuleID.NewHires,
    label: "New Hires",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: {
        ...CollectionPeriodSchema,
        defaultValue: CollectionPeriod.Daily,
        enumeration: CollectionPeriodOptions.slice(1),
        aggregations: [RuleAggregationType.Sum],
      },
    },
  },
  [RuleID.OutsideOfPayBand]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.RangeBased,
    description:
      "Check if an employee's compensation is above or below their pay band.",
    id: RuleID.OutsideOfPayBand,
    label: "Outside of Pay Band",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      includeAboveBand: {
        ...BooleanSchema,
        title: "Include Above Band",
        key: "includeAboveBand",
      },
      includeBelowBand: {
        ...BooleanSchema,
        title: "Include Below Band",
        key: "includeBelowBand",
      },
    },
  },
  [RuleID.PayChange]: {
    type: RuleType.EventBased,
    description: `Record when an employee's annualized pay changes.`,
    id: RuleID.PayChange,
    label: "Pay Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: {
        ...CollectionPeriodSchema,
        defaultValue: CollectionPeriod.Daily,
        enumeration: CollectionPeriodOptions.slice(1),
        aggregations: [RuleAggregationType.Sum],
      },
    },
  },
  [RuleID.PayCompression]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.RangeBased,
    description:
      "Check if an employee is above or below the average pay relative to the amount of time spent in their role compared to their peers.",
    id: RuleID.PayCompression,
    label: "Pay Compression",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      tenureThreshold: {
        ...ThresholdSchema,
        key: "tenureThreshold",
        title: "Tenure Threshold",
        description: `An employee's tenure should be <threshold>% above or below the average to qualify for pay compression.`,
      },
      payThreshold: {
        ...ThresholdSchema,
        key: "payThreshold",
        title: "Pay Threshold",
        description: `An employee's pay should be <threshold>% above or below the average to qualify for pay compression.`,
      },
      highTenureLowPay: {
        ...BooleanSchema,
        title: "High Tenure, Low Pay",
        description:
          "Include employees those tenure is higher than average, while pay is lower than average.",
        key: "highTenureLowPay",
      },
      lowTenureHighPay: {
        ...BooleanSchema,
        title: "Low Tenure, High Pay",
        description:
          "Include employees those tenure is lower than average, while pay is higher than average.",
        key: "lowTenureHighPay",
      },
    },
  },
  [RuleID.StalePlans]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description:
      "Count the # of plans which have not been updated in some time.",
    id: RuleID.StalePlans,
    label: "Stale Plans",
    resources: [ResourceType.Plan],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      stalenessThreshold: {
        ...ThresholdSchema,
        key: "stalenessThreshold",
        defaultValue: 12,
        type: DataType.NaturalNumber,
        title: "Stale After (months)",
        description:
          "If a plan has not changed after this many months, create an insight.",
      },
    },
  },
  [RuleID.StalePayChanges]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description:
      "Count the # of employees who have not had a pay change in some time.",
    id: RuleID.StalePayChanges,
    label: "Stale Pay Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      stalenessThreshold: {
        ...ThresholdSchema,
        key: "stalenessThreshold",
        defaultValue: 12,
        type: DataType.NaturalNumber,
        title: "Stale After (months)",
        description:
          "If an employee's pay has not changed after this many months, create an insight.",
      },
    },
  },
  [RuleID.StaleTitleChanges]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description:
      "Count the # of employees who have not had a title change in some time.",
    id: RuleID.StaleTitleChanges,
    label: "Stale Title Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
      stalenessThreshold: {
        ...ThresholdSchema,
        key: "stalenessThreshold",
        defaultValue: 12,
        type: DataType.NaturalNumber,
        title: "Stale After (months)",
        description:
          "If an employee's job title has not changed after this many months, create an insight.",
      },
    },
  },
  [RuleID.StatusChange]: {
    type: RuleType.EventBased,
    description: `Record when an employee's status changes (e.g., from active to on leave).`,
    id: RuleID.StatusChange,
    label: "Status Changes",
    resources: [ResourceType.Employee],
    alertDefinition: {
      collectionPeriod: {
        ...CollectionPeriodSchema,
        defaultValue: CollectionPeriod.Daily,
        enumeration: CollectionPeriodOptions.slice(1),
        aggregations: [RuleAggregationType.Sum],
      },
    },
  },
  [RuleID.UnmappedJobTitles]: {
    type: RuleType.AggregateBased,
    metricType: MetricType.SingleValue,
    description: "Count the # of unmapped job titles in the system.",
    id: RuleID.UnmappedJobTitles,
    label: "Unmapped Job Titles",
    resources: [ResourceType.JobTitle],
    alertDefinition: {
      collectionPeriod: CollectionPeriodSchema,
    },
  },
};

export enum MetricID {
  Count = "count",
  AboveThreshold = "above",
  BelowThreshold = "below",
  WithinThreshold = "inside",
  NoData = "no-data",
}
