export interface EmployeeTimelineData {
  date: Date;
  value: number;
  currency: string;
  tooltips: string[];
  type: EmployeeTimelineEvent;
}

export enum EmployeeTimelineEvent {
  BecameInactive = "became-inactive",
  DepartmentChange = "department-change",
  EquityGrant = "equity",
  ExemptionChange = "exemption-change",
  Hire = "hire",
  Multiple = "multiple",
  ManagerChange = "manager-change",
  PayChange = "pay-change",
  PerformanceReview = "performance",
  ReturnedFromLeave = "returned-from-leave",
  TitleChange = "title-change",
  WentOnLeave = "went-on-leave",
}
