import type { ReportingSchema } from "../../data/report/type";
import type { ResourceType } from "../../data/constant";
import { MetricID, RuleID } from "./constant";

export interface RuleResourceResult {
  insights: Insight[];
}

export interface Insight {
  description: string;
}

export type Metrics = { [name in MetricID]?: number };
export type Resources = { [resource_id: string]: RuleResourceResult };

export interface RuleResult {
  id: RuleID;
  resources: Resources;
  metrics: Metrics;
}

export enum RuleType {
  /** Rules which act on a single resource. */
  ResourceBased = "resource",
  /** Rules which act on an aggregate or group of resources. */
  AggregateBased = "aggregate",
  /** Rules which trigger on a resource-based event. */
  EventBased = "event",
}

export enum CollectionPeriod {
  /** Immediately create the alert if the data has changed. */
  Immediate = "immediate",

  /** Ensure only one alert is created per day. */
  Daily = "daily",

  /** Ensure only one alert is created per week. */
  Weekly = "weekly",

  /** Ensure only one alert is created per month. */
  Monthly = "monthly",
}

export enum MetricType {
  /** One measurement is collected. */
  SingleValue,

  /** Measurement is based on a range (e.g. payband adherance). */
  RangeBased,
}

export enum RuleReminder {
  NoReminder = "no-reminder",
  Weekly = "weekly",
  Monthly = "monthly",
}

export type AlertDefinition = { [key: string]: AlertSchema };

export interface RuleSchema {
  id: RuleID;

  type: RuleType;

  metricType?: MetricType;

  /** A user-facing description for the rule. */
  description: string;

  /** A user-facing name for the rule. */
  label: string;

  /** Resource type(s) that this rule can be run against. */
  resources: ResourceType[];

  /** Details for alerts based on this rule. */
  alertDefinition?: AlertDefinition;
}

export enum RuleResultState {
  /** Nothing about the result is remarkable. */
  Normal = "normal",

  /** Result contains information that we want to report on. */
  InAlert = "in-alert",
}

export type RuleDetails = { [key in RuleID]?: unknown };
export type RuleData = {
  rules: Set<RuleID>;
  rule_details: RuleDetails;
  rule_reminder: RuleReminder;
};

export enum RuleAggregationType {
  MostRecent = "most-recent",
  Sum = "sum",
}

export interface AlertSchema extends Omit<ReportingSchema, "aggregations"> {
  aggregations?: RuleAggregationType[];
}
