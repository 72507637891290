import React from "react";
import * as R from "ramda";

import { useApi } from "milio-api/context";
import { HistoryApi } from ".generated/apis";
import {
  DecoratedHistoryItem,
  EmployeeStatus,
  HistoryCategory,
  FilterValue,
  QueryDTO,
} from ".generated/models";
// import {
//   Alert,
//   CollectionPeriod,
//   EmployeeStatus,
//   MetricID,
//   Operator,
//   PayRate,
//   RuleAggregationType,
//   RuleID,
// } from "@milio/lib/data";
import { CollectionPeriod } from "@milio/lib/data/rule/type";
import { Operator } from "@milio/lib/data/report/constant";
import { PayRate } from "@milio/lib/data/employee/constant";
import { RuleAggregationType } from "@milio/lib/data/rule/type";
import { MetricID, RuleID } from "@milio/lib/data/rule/constant";
import { DateTime } from "luxon";
import { KeyMethod, useDataPaginated } from "./useDataPaginated";
import { filterValuesToLogicDefinition } from "./useEmployeeRows";

type Alert = any;
const PAGE_SIZE = 500;

const COUNT_PATH = ["data", "metrics", MetricID.Count, "value"];
const ID_PATH = ["data", "metrics", MetricID.Count, "context", "resources"];

const HistoryTypeToRuleIdMap: {
  [key in HistoryCategory]?: RuleID;
} = {
  [HistoryCategory.Hired]: RuleID.NewHires,
  [HistoryCategory.BaseCompensationIncrease]: RuleID.PayChange,
  [HistoryCategory.BaseCompensationDecrease]: RuleID.PayChange,
  [HistoryCategory.HourlyBaseCompensationIncrease]: RuleID.HourlyPayChange,
  [HistoryCategory.HourlyBaseCompensationDecrease]: RuleID.HourlyPayChange,
  [HistoryCategory.WentOnLeave]: RuleID.StatusChange,
  [HistoryCategory.BecameInactive]: RuleID.StatusChange,
  [HistoryCategory.ReturnedFromLeave]: RuleID.StatusChange,
  [HistoryCategory.ManagerChange]: RuleID.ManagerChange,
  [HistoryCategory.JobTitleChange]: RuleID.JobTitleChange,
  [HistoryCategory.BecameExempt]: RuleID.ExemptionStatusChange,
  [HistoryCategory.BecameNonExempt]: RuleID.ExemptionStatusChange,
  [HistoryCategory.DepartmentChange]: RuleID.DepartmentChange,
};

const findNextAlert = R.curry(
  (period: CollectionPeriod, type: RuleAggregationType, remaining: Alert[]) => {
    let next = R.head(remaining);
    const nextDate = getNextDate(
      period,
      R.prop("collected_at", next)
    ) as string;

    const [batch, nextBatch] = R.partition<Alert>(
      R.propSatisfies((d: string) => {
        return d >= nextDate;
      }, "collected_at"),
      remaining
    );

    if (next && type === RuleAggregationType.Sum) {
      next = R.pipe(
        R.set(
          R.lensPath(COUNT_PATH),
          R.pipe(R.map(R.pipe(R.path(ID_PATH), R.uniq, R.length)), R.sum)(batch)
        ),
        R.set(
          R.lensPath(ID_PATH),
          R.pipe(
            R.map(R.path(ID_PATH)),
            R.flatten,
            R.uniq,
            R.sortBy(R.identity)
          )(batch)
        )
      )(next);
    }

    return [next, nextBatch] as [Alert, Alert[]];
  }
);

const getNextDate = (period: CollectionPeriod, reference: string): string => {
  const referenceDT = DateTime.fromISO(reference).setZone("utc");
  switch (period) {
    case CollectionPeriod.Daily:
      return referenceDT.startOf("day").toISO();
    case CollectionPeriod.Weekly:
      return referenceDT.startOf("week").toISO();
    case CollectionPeriod.Monthly:
      return referenceDT.startOf("month").toISO();
  }
};

const aggregateOverCollectionPeriod = (ruleAlerts: Alert[]) => {
  const aggregationPeriod = CollectionPeriod.Daily;

  const aggregationMethod = RuleAggregationType.Sum;

  const findNext = findNextAlert(aggregationPeriod, aggregationMethod);

  const result = [];
  let [next, remaining] = findNext(ruleAlerts);

  while (next) {
    result.push(next);
    [next, remaining] = findNext(remaining);
  }

  return result;
};

export const filterAndAggregate = (alerts: Alert[]): Alert[] => {
  const sortFn = R.sort(
    R.descend<Alert>((a) => [a.collected_at, a.id].join(","))
  );
  const byRule = R.collectBy(R.prop("rule_id"), alerts);
  const sortedByRule = R.map(sortFn, byRule);
  const aggregated = R.map(
    aggregateOverCollectionPeriod,
    sortedByRule
  ) as Alert[][];
  const flattened = R.flatten(aggregated);

  return sortFn(flattened);
};

const historyToAlert = (historyItem: DecoratedHistoryItem): Partial<Alert> => {
  return {
    id: historyItem.id,
    owner_id: null,
    rule_id: R.prop(historyItem.category, HistoryTypeToRuleIdMap),
    collected_at: DateTime.fromISO(historyItem.effective_date)
      .setZone("utc")
      .toISO(),
    data: {
      insights: [],
      metrics: {
        [MetricID.Count]: {
          value: 1,
          context: { resources: [historyItem.resource_id] },
        },
      },
    },
  };
};

const removeEventsForInactive = R.reject(
  R.where({
    resource: R.propEq("status", EmployeeStatus.Inactive),
    category: R.complement(R.equals)(HistoryCategory.BecameInactive),
  })
);

const onlyShowOnlyHourlyEventsWhenHourly = R.reject(
  R.where({
    resource: R.propEq("pay_rate", PayRate.Hourly),
    category: R.flip(R.includes)([
      HistoryCategory.BaseCompensationDecrease,
      HistoryCategory.BaseCompensationIncrease,
    ]),
  })
);

// these shouldn't exist, but can happen if there's an indexing bug so remove
// them rather than make all the components deal with null
const removeNullCategoryItems = R.reject(
  R.where({
    category: R.isNil,
  })
);

export const clientAlertFilter = R.pipe(
  removeEventsForInactive,
  removeNullCategoryItems,
  onlyShowOnlyHourlyEventsWhenHourly
);

const getKey: KeyMethod = ({
  tag,
  pageIndex,
  previousPageData,
  initialQuery,
}) => {
  // reached the end
  if (previousPageData && !previousPageData.offset) return null;
  // first page, we don't have `previousPageData`
  if (pageIndex === 0)
    return R.mergeRight(initialQuery, {
      limit: PAGE_SIZE,
      tag,
    });

  // add the cursor to the API endpoint
  return R.mergeRight(initialQuery, {
    limit: PAGE_SIZE,
    offset: previousPageData.offset,
    tag,
  });
};

const createDefaultQuery: () => QueryDTO = () => {
  const filters: FilterValue[] = [
    {
      field: "effective_date",
      value: DateTime.now().minus({ weeks: 2 }).toISO(),
      operator: Operator.GreaterThanOrEqualTo,
    },
  ];
  const def = filterValuesToLogicDefinition(filters);
  return { limit: PAGE_SIZE, filters: def };
};

export const useSignals = () => {
  const { api } = useApi(HistoryApi);
  const [query, setQuery] = React.useState<QueryDTO>(createDefaultQuery());
  const { data, loading, error } = useDataPaginated<DecoratedHistoryItem>(
    { tag: "history", initialQuery: query },
    getKey,
    (params) => {
      return api.searchHistory({ queryDTO: params });
    }
  );

  const alerts = React.useMemo(() => {
    if (!data) return [];
    return R.pipe(
      R.defaultTo([]) as (
        d: DecoratedHistoryItem[] | undefined
      ) => DecoratedHistoryItem[],
      clientAlertFilter,
      R.map(historyToAlert),
      filterAndAggregate
    )(data) as unknown as Alert[];
  }, [data]);

  return { isLoading: loading, error, alerts, query, setQuery };
};
